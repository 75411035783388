// @flow
import * as React from 'react';
import { useRouter, } from 'next/router';
import AboveMainHeader from '../AboveMainHeader/AboveMainHeader';
import SectionTitleLabels from '../SectionTitleLabels/SectionTitleLabels';
import useSectionData from '../../hooks/Page/useSectionData';

type SectionPageTitleProps = {
  name?: string;
}

export default function SectionPageTitle({ name, }: SectionPageTitleProps) {
  const section = useSectionData();
  const router = useRouter();
  if (section?.hideDisplayName) {
    return null;
  }

  let Component = null;

  switch (true) {
    case (router.query.path || '').startsWith('/labels/'):
      Component = SectionTitleLabels;
      break;
    case [
      '/newsletter4all/pages',
      '/labels',
      '/binge',
      '/deepdive/',
    ].some(path => (router.query.path || '').startsWith(path)):
      Component = () => null;
      break;
    default:
      Component = AboveMainHeader;
      break;
  }
  const { path, } = router.query || {};
  const isDeepDiveSection = path === '/deepdive';
  const paddingBottom = isDeepDiveSection ? 0 : undefined;
  const miscStyles = paddingBottom !== undefined ? { paddingBottom, } : undefined;

  return (
    <Component
      name={name || section?.name || ''}
      {...(miscStyles ? { miscStyles, } : {})}
    />
  );
}

SectionPageTitle.defaultProps = {
  name: null,
};
