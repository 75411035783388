// @flow
import React from 'react';
import { useFela, } from 'react-fela';
import { borderTop, } from '@haaretz/htz-css-tools';
import LayoutContainer from '../PageLayout/LayoutContainer';
import H from '../AutoLevels/H';

export type ExtraLink = {
  contentId: string,
  contentName?: string,
  href: string,
  linkText?: string,
};

type Props = {
  name: string,
  miscStyles: ?Object,
};

SectionTitleLabels.defaultProps = {
  miscStyles: null,
};
export default function SectionTitleLabels({ name, miscStyles, }: Props) {
  const { css, theme, } = useFela();
  return (
    <LayoutContainer
      namedBgc={[ { from: 's', value: 'transparent', }, { until: 's', value: 'white', }, ]}
      miscStyles={{
        marginTop: '3rem',
        marginBottom: '2rem',
        fontFamily: theme.fontStacks.commercial,
        paddingBottom: '2rem',
        ...(miscStyles || {}),
      }}
    >
      <header
        className={css({
          paddingTop: '3rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
          extend: [
            borderTop({ lines: 1, width: 2, style: 'solid', color: theme.color('commercial'), }),
            theme.mq({ until: 's', }, { paddingEnd: '2rem', }),
            theme.mq({ from: 's', }, { paddingEnd: '4rem', }),
            theme.mq({ until: 's', }, { paddingStart: '2rem', }),
            theme.mq({ from: 'l', }, { marginTop: '5rem', }),
          ],
        })}
      >
        <H
          className={css({
            color: theme.color('commercial'),
            extend: [
              theme.type(5, { fromBp: 'xl', }),
              theme.type(2, { untilBp: 'xl', }),
            ],
          })}
        >
          {name}
        </H>
      </header>
    </LayoutContainer>
  );
}
