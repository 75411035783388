// @flow
import * as React from 'react';
import {
  useGetComponent,
  type ColorPropType,
} from '@haaretz/htz-components';

type Props = {
  content: Array<Object>,
  isSectionArticle: boolean,
  bgc?: ?ColorPropType,
};

function HeaderSlot({ content, bgc, isSectionArticle, }: Props): React.Node {
  const getElements = useGetComponent();
  if (content) {
    return content.map(element => {
      const Element = getElements(element.inputTemplate, {
        ...element,
        loadPriority: element.loadPriority,
        isExpanded: element.isExpanded,
        preventRender: element.preventRender,
      });
      return (
        <Element
          key={element.contentId}
          {...element}
          {...(element.inputTemplate === 'PageNavigation'
            ? {
              pageType: isSectionArticle ? 'sectionArticle' : 'section',
              rowBgc: bgc,
            }
            : {})}
        />
      );
    });
  }
  return null;
}

HeaderSlot.defaultProps = { isSectionArticle: false, };

export default HeaderSlot;
