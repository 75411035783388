// @flow
import React from 'react';
import { useFela, } from 'react-fela';
import { borderTop, } from '@haaretz/htz-css-tools';
import { useRouter, } from 'next/router';
import LayoutContainer from '../PageLayout/LayoutContainer';
import H from '../AutoLevels/H';
import HtzLink from '../HtzLink/HtzLink';
import AuthorAlert from '../Alerts/AuthorAlert';
import { useEventTracker, } from '../../utils/EventTracker';
import useArticleId from '../../hooks/Page/useArticleId';

export type ExtraLink = {
  contentId: string,
  contentName?: string,
  href: string,
  linkText?: string,
};

type Props = {
  name: string,
  extraLinks?: ?ExtraLink[],
  isH1: boolean,
  miscStyles: ?Object,
};

AboveMainHeader.defaultProps = {
  isH1: false,
  extraLinks: null,
  miscStyles: null,
};
export default function AboveMainHeader({ name, extraLinks, isH1, miscStyles, }: Props) {
  const alertsToggleBtnRef = React.useRef();

  const { css, theme, } = useFela();
  const router = useRouter();
  const { biAction, } = useEventTracker();
  const [ skipFocusAuthorsBtn, setSkipFocusAuthorsBtn, ] = React.useState(true);
  const [ isShowAuthorAlertsForm, setIsShowAuthorAlertsForm, ] = React.useState(
    false
  );

  const articleId = useArticleId();

  const isBlogSection = !articleId && router.asPath.startsWith('/blogs');

  React.useEffect(() => {
    if (
      !skipFocusAuthorsBtn
      && !isShowAuthorAlertsForm
      && alertsToggleBtnRef.current
    ) {
      alertsToggleBtnRef.current.focus();
    }
  }, [ isShowAuthorAlertsForm, skipFocusAuthorsBtn, ]);

  const toggleAuthorAlertsForm = () => {
    if (skipFocusAuthorsBtn) setSkipFocusAuthorsBtn(false);
    setIsShowAuthorAlertsForm(prevState => !prevState);
  };


  return (
    <LayoutContainer
      namedBgc={[ { from: 's', value: 'transparent', }, { until: 's', value: 'white', }, ]}
      miscStyles={{
        paddingBottom: '2rem',
        ...(miscStyles || {}),
      }}
    >
      <header
        className={css({
          paddingTop: '3rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
          extend: [
            borderTop({ lines: 1, width: 2, style: 'solid', color: theme.color('list', 'listViewHeader'), }),
            theme.mq({ until: 's', }, { paddingEnd: '2rem', paddingTop: '2rem', }),
            theme.mq({ from: 's', }, { paddingEnd: '4rem', }),
            theme.mq({ until: 's', }, { paddingStart: '2rem', }),
            theme.mq({ from: 'l', }, { marginTop: '5rem', }),
          ],
        })}
      >
        <H
          isH1={isH1}
          className={css({
            color: theme.color('list', 'listViewHeader'),
            extend: [
              theme.type(5, { fromBp: 'xl', }),
              theme.type(2, { fromBp: 's', untilBp: 'xl', }),
              theme.type(1, { untilBp: 's', }),
            ],
          })}
        >
          {name}
        </H>
        <ExtraLinks extraLinks={extraLinks} css={css} theme={theme} />
        {isBlogSection ? (
          <AuthorAlert
            isBlog
            author={{}}
            isModal
            iconSize={3}
            biAction={biAction}
            ref={alertsToggleBtnRef}
            onToggle={() => toggleAuthorAlertsForm()}
            miscStyles={{
              gridArea: 'alerts',
              justifySelf: 'start',
              fontFamily: theme.fontStacks[theme.framedFont],
              alignSelf: [
                { until: 's', value: 'start', },
                { from: 's', value: 'center', },
              ],
            }}
            origin="blog-alert-section"
          />
        ) : null}
      </header>
    </LayoutContainer>
  );
}

function extraLinksClasses({ theme, }) {
  return {
    marginTop: '1rem',
    color: theme.color('neutral', '-3'),
    marginInlineStart: 'auto',
    fontWeight: '700',
    extend: [
      theme.mq({ until: 's', }, { display: 'none', }),
      theme.type(-1, { fromBp: 's', untilBp: 'l', }),
      theme.type(-1, { fromBp: 'l', untilBp: 'xl', lines: 4, }),
      theme.type(-1, { fromBp: 'xl', }),
    ],
  };
}

ExtraLinks.defaultProps = {
  extraLinks: null,
};
function ExtraLinks({ extraLinks, }: { extraLinks?: ?ExtraLink[], }) {
  const { css, theme, } = useFela();
  const ulClasses = css(extraLinksClasses);
  const liClasses = css({ display: 'inline-block', });

  return extraLinks ? (
    <ul className={ulClasses}>
      {extraLinks.map((item, idx) => (
        <li key={item.contentId} className={liClasses}>
          <HtzLink
            href={item.href}
            className={css({
              ':hover': { color: theme.color('neutral', -1), },
              ':after':
                idx !== extraLinks.length - 1
                  ? {
                    content: '"|"',
                    display: 'inline-block',
                    marginInlineStart: '1rem',
                    marginInlineEnd: '1rem',
                  }
                  : undefined,
            })}
          >
            {item.linkText || item.contentName}
          </HtzLink>
        </li>
      ))}
    </ul>
  ) : null;
}
