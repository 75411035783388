// @flow

import { useQuery, } from 'react-apollo';
import { SECTION_DATA_QUERY, } from '@haaretz/graphql';
import { useRouter, } from 'next/router';

export default function useSectionData() {
  const router = useRouter();
  const path = (router && router.query.path) || '/';


  const { data, loading, error, } = useQuery(SECTION_DATA_QUERY, {
    fetchPolicy: 'cache-first',
    variables: {
      path,
    },
  });

  if (loading) return null;
  if (error) {
    console.error('Section error', error);
    return null;
  }

  if (!data) return null;

  return data.Page?.slots?.section;
}
